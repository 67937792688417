<template>
  <div class="">
    <Carousel
      :class="wrapperClass"
      :opts="{
        align: 'start',
        loop: true,
        inViewThreshold: 0.9,
        skipSnaps: true,
      }"
      :plugins="[autoplay]"
      @init-api="setApi"
    >
      <CarouselContent>
        <CarouselItem
          v-for="(item, index) in items"
          :key="get(item, itemKey, `${item}`)"
          :class="contentClass"
          @mouseenter="autoplay.stop"
          @mouseleave="[autoplay.reset(), autoplay.play()]"
        >
          <!-- slot for each carousel item -->
          <slot :item="item" :index="index" />
        </CarouselItem>
      </CarouselContent>
    </Carousel>

    <div class="flex items-center justify-center gap-x-4 h-20">
      <Button
        aria-label="Previous"
        class="primary-gradient p-2 md:p-4"
        @click="api?.scrollTo(current - 2)"
        ><Icon icon="mdi-chevron-left" class="text-2xl"></Icon
      ></Button>
      <!-- bullets for slide -->
      <div class="flex justify-center items-center gap-x-2">
        <button
          aria-label="Go to slide"
          v-for="i in totalCount"
          :key="i"
          :class="[
            'w-4 h-1.5 md:w-6 md:h-2 rounded-full bg-foreground/20',
            i === current ? 'bg-primary' : '',
          ]"
          @click="api?.scrollTo(i - 1)"
        ></button>
      </div>
      <Button
        aria-label="Next"
        class="primary-gradient p-2 md:p-4"
        @click="api?.scrollTo(current)"
        ><Icon icon="mdi-chevron-right" class="text-2xl"></Icon
      ></Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue/dist/iconify.js";
import { watchOnce } from "@vueuse/core";
import Autoplay, { type AutoplayOptionsType } from "embla-carousel-autoplay";
import type { CarouselApi } from "~/components/ui/carousel";
import { get, merge } from "lodash-es";

const props = defineProps({
  items: {
    type: Array as PropType<any[]>,
    required: true,
  },
  itemKey: {
    type: String,
    required: true,
  },
  autoplay: {
    type: [Object, false] as PropType<AutoplayOptionsType | boolean>,
    default: () => ({
      delay: 10000,
      stopOnMouseEnter: true,
      stopOnInteraction: false,
    }),
  },
  wrapperClass: {
    type: String,
    default: "w-full",
  },
  contentClass: {
    type: String,
    default: "flex-none",
  },
});

const autoplay = Autoplay(
  merge(
    {
      delay: 10000,
      stopOnMouseEnter: true,
      stopOnInteraction: false,
    },
    props.autoplay
  )
);

const api = ref<CarouselApi>();
const totalCount = ref(0);
const current = ref(0);
function setApi(val: CarouselApi) {
  api.value = val;
}
watchOnce(api, (api) => {
  if (!api) return;

  totalCount.value = api.scrollSnapList().length;
  current.value = api.selectedScrollSnap() + 1;

  api.on("select", () => {
    current.value = api.selectedScrollSnap() + 1;
  });
});

const total = computed(() => {
  return api.value?.scrollSnapList().length;
});
</script>

<style scoped></style>
